import julioPhoto from '../img/newProPic.jpg';
import { Container, Row, Col, Button } from 'reactstrap';
import { FaLinkedin } from 'react-icons/fa';

function About() {
  return (
    <div>
      <Container className='about' fluid>
        <Row className='aboutSection'>
          <div className='aboutMeTitle'>About Me</div>
          <Col className='aboutPhoto'>
            <div className='aboutJuliobg'>
              <img
                className='picOfJulio'
                src={julioPhoto}
                alt=''
                height='400'
              />
              <div className='aboutName'>Julio C Jarvis, LCSW</div>
              <div className='connect'>Connect with Linkedin</div>
              <Button
                className='aboutLinkedBtn'
                type='button'
                value='Linked'
                href='https://www.linkedin.com/in/julio-jarvis-lcsw-9aa9604'
              >
                <FaLinkedin className='aboutBtnLogo' />
              </Button>
            </div>
          </Col>
          <Col className='aboutInfo'>
            <p>
              My approach to therapy is empathic, collaborative, and unique to
              each individual's own needs. I believe the most important
              component of effective counseling is a genuine relational
              connection. I strive to create a therapeutic environment in which
              clients feel comfortable, respected, supported, and understood so
              that they may actively explore personal issues and experiment with
              new ways of thinking and behaving. Too often we spend our lives
              living either in the past or in the future, and consequently
              struggling with feelings of depression or anxiety. Instead of
              trying to change, I prefer growth.
            </p>
            <p>
              I will work with you to help you learn how to spend more time in
              the here and now. I utilize various components of Cognitive
              Behavioral Therapy (CBT), Dialectical Behavior Therapy (DBT),
              Acceptance and Commitment Therapy (ACT), Solution Focused Brief
              Therapy (SFBT), and Mindfulness techniques with a Psychodynamic
              approach. I believe strongly in empowerment and the human capacity
              for adjustment and growth.<div id='uniqueExperience'></div> Together I am confident we can make a
              difference!
            </p>
           
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default About;
