import { Container } from 'reactstrap';

const CommonInsuranceTerms = () => {
  return (
    <Container className='CommonInsuranceTerms' fluid>
      <Container className='rAndIpage'>
        <h2 className='rAndITitle'>Common Insurance Terms</h2>
        <div className='rAndI'>
          <b>Deductible:</b>
          <br></br>A deductible is the amount a member must pay out-of-pocket
          before insurance benefits kick-in. Deductibles are often listed
          annually, by individual and family. In other words, if you have a
          $300/$900 deductible, it means that $300 must be paid for an
          individual member OR $900 for the combined family before insurance
          will begin paying for services. Deductibles correspond to your plan's
          policy year (e.g. calendar or fiscal).
        </div>
        <div className='rAndI'>
          <b>Co-Payment / Co-Insurance:</b>
          <br></br>A co-payment is the portion of the charge for the appointment
          that the member if responsible for. This amount may be a specific
          dollar amount, a percentage for the total charge, or a combination of
          the two.
        </div>
        <div className='rAndI'>
          <b>Pre-Authorization of Services:</b>
          <br></br>Some plans require you, the member, to call in advance of
          your appointment to receive authorization for a covered service.
          Failure to obtain pre-authorization can result in the member being
          responsible for the entire fee for the service.
        </div>
        <div className='rAndI'>
          <b>In-Network Benefits:</b>
          <br></br>Many insurance plans have provider panels (professionals that
          are contracted with your insurance company), which are the
          professionals whose services the plan will pay the maximum benefit
          for.
        </div>
        <div className='rAndI'>
          <b>Out-of-Network Benefits:</b>
          <br></br>Some plans allow members to "swing-out" of the panel and see
          any provider they choose. The main disadvantage of going outside your
          network is you may have to pay a higher deductible and/or copay than
          if you use a provider who is considered in-network.
        </div>
        <div className='rAndI'>
          <em>
            Notice: To ensure that your insurance plan covers teletherapy please
            include the name of your insurance company in your initial email so
            that we can help with verification.
          </em>
        </div>
      </Container>
    </Container>
  );
};

export default CommonInsuranceTerms;
