import { Container, Row, Col, Button, Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { FaEnvelope, FaPhoneSquareAlt } from 'react-icons/fa';
import umhca from '../img/umhca.png';

function Footer() {
  return (
    <footer className='footer'>
      <Container fluid>
        <Row md='4' sm='2' xs='1'>
          <Col>
            <h4>Contact </h4>
            <Button
              className='footerPhone'
              outline
              color='info'
              href='tel:+18018715728'
            >
              <FaPhoneSquareAlt className='phoneLogo' />
            </Button>
            <Button
              type='button'
              className='footerEmail'
              outline
              color='info'
              href='mailto:balanced.earth.wellness@gmail.com'
            >
              <FaEnvelope className='emailLogo' />
            </Button>
          </Col>
          <Col>
            <div>
              <h4>Links</h4>
            </div>
            <div>
              <Nav className='footerLinks'>
                <NavItem>
                  <NavLink to='/' className='footLink'>
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to='/about' className='footLink'>
                    About
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to='/contact' className='footLink'>
                    Contact
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Col>
          <Col>
            <Button className='umhcaLink' href='https://umhca.org'>
              <img src={umhca} alt='' height='70' className='umhcaImage' />
            </Button>
          </Col>
          <Col className='footerColumn3'>
            <h6 className='legalName'>Balanced Earth Wellness, LLC</h6>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
