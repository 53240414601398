import '../App.css';
import Header from './Header';
import FeaturedInfo from './FeaturedInfo';
import MoreInfo from './MoreInfo';

function Home() {
  return (
    <div>
      <Header />
      <FeaturedInfo />
      <MoreInfo />
    </div>
  )
}

export default Home;