import { Container } from 'reactstrap';

const Rates = () => {
  return (
    <Container className='RatesPage' fluid> 
    <Container className='rAndIpage'>
      <h2 className='rAndITitle'>Rates and Insurance</h2>
      <div className='rAndISection'>
        <div className='rAndI'>
          <strong>
            <u>Rates:</u>
          </strong>
        </div>
        <div className='rAndI'>
          <strong>Individual:</strong> $90 - $150 per session with a sliding
          scale
        </div>
        <div className='rAndI'>
          <strong>Crisis debriefing group (suicide):</strong> Contact for more
          info
        </div>
        <div className='rAndI'>
          I accept several local health insurance plans as an in-plan provider
          and qualify as an out-of-plan provider for several more. Currently, I
          am in-plan with:
        </div>
        <ul className='rAndI'>
          <strong>
          <li>
            BCBS (Accepted)
          </li>
          </strong>
          <strong>
          <li>
            TRICARE West (Accepted - <em>out of network</em>)
          </li>
          </strong>
          <strong>
          <li>
            Select Health (Accepted)
          </li>
          </strong>
          <strong>
          <li>
            UUHP (Accepted)
          </li>
          </strong>
          <strong>
          <li>
            UHC-UBH (Accepted)
          </li>
          </strong>
        </ul>
        <div className='rAndI'>
          If you have another insurance company, you pay for services at the
          time they are rendered and we provide a Superbill that you submit to
          your insurance company to seek reimbursement directly from them. It is
          important to verify your out-of-network benefit with your insurance
          company.
        </div>
        <div className='rAndI'>
          <em>
            Please note:<br></br> If you have a health insurance policy, it will
            usually provide some coverage for mental health treatment. I can
            help you receive the benefits to which you are entitled, however
            you, not your insurance company, are responsible for full payment of
            my fees. It is important that you find out exactly what mental
            health services your insurance policy covers.
          </em>
        </div>
        <div className='rAndI'>
          Please check your coverage carefully by asking the following
          questions:
        </div>
        <ul className='rAndI'>
          <strong>
            <li>Do I have mental health insurance benefits?</li>
            <li>What is my deductible and has it been met?</li>
            <li>How many sessions per year does my health insurance cover?</li>
            <li>What is the coverage amount per therapy session?</li>
            <li>Is approval required from my primary care physician?</li>
            <li>
              (For clients whose insurance is not listed above) What is my
              out-of-network mental health benefit?
            </li>
          </strong>
        </ul>
      </div>
    </Container>
    </Container>
  );
}

export default Rates;
