import { Row, Col } from 'reactstrap';
import { FaPhoneVolume, FaArrowRight } from 'react-icons/fa';

function Contact() {


  return (
    <div className='contactus'>
      <div className='card-contact'>
        <h3 className='contactTitle'>Contact Us</h3>
        <Row>
          <Col>
            <div className='ringMessage'>
              <div className='info info-horizontal'>
                <div className='phoneIcon'>
                  <FaPhoneVolume />
                </div>
              </div>
              <div className='info info-horizontal'>
                <div className='description'>
                  <h4 className='info-title'>Give us a ring</h4>
                  <div className='contact-phone'>
                    Julio Jarvis <br />
                    <a className='contactPhone' href='tel:+18018715728'>
                      (801)871-5728
                    </a>{' '}
                    <br />
                  </div>
                </div>
                <div>
                  <h4>Send us a message</h4>
                </div>
                <div className='arrowIcon'>
                  <FaArrowRight />
                </div>
              </div>
            </div>
          </Col>
          <Col>
          
            <form name='contact' method='post' data-netlify='true' action='/confirmation-page'>
              <input type='hidden' name='form-name' value='contact' />
              <p>
                <label className='nameLabel' htmlFor='name'>
                  Name
                </label>{' '}
                <br />
                <input
                  className='nameArea'
                  type='text'
                  id='name'
                  name='name'
                  required
                />
              </p>
              <p>
                <label className='emailLabel' htmlFor='email'>
                  Email
                </label>{' '}
                <br />
                <input
                  className='emailArea'
                  type='email'
                  id='email'
                  name='email'
                  required
                />
              </p>
              <p>
                <label className='messageLabel' htmlFor='message'>
                  Message
                </label>{' '}
                <br />
                <textarea
                  className='messageArea'
                  id='message'
                  name='message'
                  required
                ></textarea>
              </p>
              <p>
                <input className='contactSubmitBtn' type='submit' value='Submit message' />
              </p>
            </form>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Contact;
