
import NavbarTop from './components/NavbarTop';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Rates from './components/Rates';
import ThankYou from './ThankYou';
import Error from './Error';
import Footer from './components/Footer';
import { Routes, Route } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';

import UniqueExperience from './components/UniqueExperience';
import CommonInsuranceTerms from './components/CommonInsuranceTerms';

function App() {
  return (
    <BrowserRouter>
<NavbarTop />
      <Routes>
      
          <Route path='/' element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='rates' element={<Rates />} />
          <Route path='uniqueExperience' element={<UniqueExperience />} />
          <Route
            path='commonInsuranceTerms'
            element={<CommonInsuranceTerms />}
          />
          <Route path='contact' element={<Contact />} />
          <Route path='thankYou' element={<ThankYou />} />
          <Route path='*' element={<Error />} />
        
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
