import NavbarTop from './NavbarTop.js'
import mainLogo from '../img/balanced-earth-wellness_logo.png';
import { Container } from 'reactstrap';

function Header() {
  return (
    <div className='headerContainer'>
      <div className='header'>
        <NavbarTop />
          <div className='container homePageLogo'>
            <div className='titleContainer container mx-auto fluid'>
              <img 
                src={mainLogo}
                className='mainLogo img-fluid mx-auto'
                alt=''
                
              />
              <p className='subtitle'>
                mental health counseling
              </p>
            </div>
        </div>
        <Container>
          <strong>
          <p className='homepageQuote'>"We believe strongly in empowerment and the human capacity
              for adjustment and growth. Together we are confident we can make a
              difference!"</p>
              </strong>
        </Container>
      </div>
    </div>
  )
}

export default Header;