import { useState } from 'react';
import {
  Container,
  Row,
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Nav,
  NavItem,
  Button,
} from 'reactstrap';
import logo from '../img/balanced-earth-wellness_logo.png';
import { NavLink, Outlet } from 'react-router-dom';

const NavbarTop = (args) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <Container>
        <Row>
          <Navbar {...args}
            className='navbar fixed-top'
            expand='lg'
            id='navbar-main'
            light
          >
            <NavbarBrand href='/'>
              <img
                src={logo}
                height='70'
                className='d-inline-block align-top'
                alt=''
              />
            </NavbarBrand>

            <NavbarToggler onClick={toggle} className='me-2' />
            <Collapse isOpen={isOpen} navbar className='navbarLinks'>
              <Nav navbar>
                <NavItem className='navLink'>
                  <NavLink
                    to='/'
                    className={({ isActive }) =>
                      isActive ? 'link active' : 'link'
                    }
                  >
                    Home
                  </NavLink>
                </NavItem>
                <NavItem className='navLink'>
                  <NavLink
                    to='/about'
                    className={({ isActive }) =>
                      isActive ? 'link active' : 'link'
                    }
                  >
                    About
                  </NavLink>
                </NavItem>
                <NavItem className='navLink'>
                  <NavLink
                    to='/uniqueExperience'
                    className={({ isActive }) =>
                      isActive ? 'link active' : 'link'
                    }
                  >
                    Unique Experience
                  </NavLink>
                </NavItem>
                <NavItem className='navLink'>
                  <NavLink
                    to='/rates'
                    className={({ isActive }) =>
                      isActive ? 'link active' : 'link'
                    }
                  >
                    Rates
                  </NavLink>
                </NavItem>
                <NavItem className='navLink'>
                  <NavLink
                    to='/commonInsuranceTerms'
                    className={({ isActive }) =>
                      isActive ? 'link active' : 'link'
                    }
                  >
                    Common Insurance Terms
                  </NavLink>
                </NavItem>
                <NavItem className='navLink'>
                  <NavLink
                    to='/contact'
                    className={({ isActive }) =>
                      isActive ? 'link active' : 'link'
                    }
                  >
                    Contact
                  </NavLink>
                </NavItem>
              </Nav>
              <Button
                className='portalLink'
                href='https://julio-j.clientsecure.me'
                color='success'
              >
                Client Portal
              </Button>
            </Collapse>
            
          </Navbar>
          <Outlet />
        </Row>
        
      </Container>
      
    </>
  );
};

export default NavbarTop;
