import { Container } from 'reactstrap';

const UniqueExperience = () => {
  return (
    <Container className='UniqueExperience' fluid>
      <Container className='rAndIpage'>
        <h2 className='rAndITitle'>Unique Experience</h2>
        <div className='rAndI'>
          <b>Specialties Include:</b>
        </div>
        <ul className='rAndI'>
          <li>Individual Therapy</li>
          <li>
            Trauma informed previous therapist with the Trauma Awareness and
            Treatment Center
          </li>
          <li>Oncology (cancer)</li>
          <li>Crisis (suicide)</li>
          <li>Group Therapy</li>
          <li>Medical Social Work</li>
          <li>Field Practicum Advisor</li>
        </ul>
        <div className='rAndI'>
          Member of the very first Mobile Crisis Outreach Team in Utah
        </div>
        <div className='rAndI'>
          Member of the very first Behavioral Health Integrated Program for
          University of Utah Clinics
        </div>
        <div className='rAndI'>ER crisis worker</div>
        <div className='rAndI'>
          Contracted consultant - Bluestone Strategy Group
        </div>
        <div className='rAndI'>
          <b>Education</b>
        </div>
        <div className='rAndI'>BA Psychology University of Utah, 2009</div>
        <div className='rAndI'>MSW University of Utah, 2011</div>
        <div className='rAndI'>
          <b>Professional Development</b>
        </div>
        <div className='rAndI'>State of Utah Designated Examiner 2019</div>
        <div className='rAndI'>
          Salt Lake County Certified Mental Health Officer - current
        </div>
        <div className='rAndI'>
          Division of Substance Abuse and Mental Health - Adult Mental Health
          Case Manager
        </div>
        <div className='rAndI'>
          State of Utah - Certified Crisis Counselor for Disaster & Bioterrorism
        </div>
        <div className='rAndI'>CPR/BLS – 2010 to current</div>
        <div className='rAndI'>ASIST Trainer (Suicide Prevention) 2013</div>
        <div className='rAndI'>
          Have done presentations for LDS Family Services, Sandy Police Dept and
          Fire Dept., Counseling Centers, Criminal Justice, Sundance Film
          Festival, SJHC and University of Utah.
        </div>
      </Container>
    </Container>
  );
};

export default UniqueExperience;
