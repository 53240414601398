import { Button } from 'reactstrap';

const ThankYou = () => {
  return (
    <section className='thankyou'>
      <h2 className='thankyouTitle'>Message Sent!</h2>
      <p className='thankyouMessage'>Thank you for sending us a message. We will get back to you as soon as possible.</p>
      <Button color='success' className='thankyouLink' href='/'>Back Home</Button>
    </section>
  );
};
export default ThankYou;