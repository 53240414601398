import { Container, Row, Col } from 'reactstrap';
import {
  FaLaptopMedical,
  FaHandHoldingHeart,
  FaPeopleArrows
} from 'react-icons/fa';

function FeaturedInfo() {
  return (
    <div className='featured'>
    <Container>
      <Row>
        <Col>
          <div className='featuredSlot1'>
            <div className='featuredIcon'>
              <FaLaptopMedical />
              </div>
              <h3>Virtual Visits</h3>
              <p className='featuredDescription'>
                Easy to use teletherapy services for your convenience. Please check with your insurance provider. In person therapy coming soon!
              </p>
            </div>
        </Col>
        <Col>
        <div className='featuredIcon'>
          <FaHandHoldingHeart />
          </div>
          <h3>Individual Therapy</h3>
          <p className='featuredDescription'>
            Over a decade of experience helping individuals find joy, peace and strength. Julio Jarvis is a fully licensed mental health provider for counseling and therapy.
          </p>
        </Col>
        <Col>
        <div className='featuredIcon'>
          <FaPeopleArrows />
          </div>
          <h3>Crisis Support</h3>
          <p className='featuredDescription'>
            Years of experience working for the University of Utah ER and Mobile Crisis Outreach Team. Also experience helping people struggling with cancer and suicide.
          </p>
        </Col>
    
      </Row>
    </Container>
    </div>
  );
}

export default FeaturedInfo;
